import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Router from './router/Router';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@shopify/polaris';
import { GenerateToken } from './store/storeSlice';
import { NavMenu } from '@shopify/app-bridge-react';
import { config } from './helper/commonApi';

const App = () => {
  const dispatch = useDispatch();
  const app_location = useLocation();
  
  if(!config.host){
    config.host = app_location?.state?.config?.host ? app_location?.state?.config?.host : config.host;
    config.shop = app_location?.state?.config?.shop ? app_location?.state?.config?.shop : config.shop;
  }

  useEffect(() => {
    dispatch(GenerateToken());
  }, []);

  const clientStoreData = useSelector(state => state?.clientStoreData);
  const { status, clientInfo } = clientStoreData;

  if (clientInfo && status) {
    if (window.location.hostname === "localhost") {
      return <Router />;
    } else {
      return (
        <>
          <NavMenu>
            <a href="/" rel="home">home</a>
            <a href="/product">Product</a>
            <a href="/Customer">Customer</a>
            <a href="/setting">Settings</a>
            <a href="/pricing_plan">Pricing plan</a>
            <a href="/feature_req">Request a Feature</a>
          </NavMenu>
          <Router />
          <Outlet />
        </>
      );
    }
  } else if (!status) {
    return <><div className='main-spinner'><Spinner accessibilityLabel="Spinner example" size="large" /></div></>;
  }
}

export default App;
