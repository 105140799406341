import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { FormLayout, LegacyCard, Page, PageActions, TextField, Select, Toast, AppProvider } from '@shopify/polaris';
import { ApiCall, GetApiCall } from '../helper/axios';
import { TitleBar } from "@shopify/app-bridge-react";
import { config } from '../helper/commonApi';
import Footer from './Footer';

const SettingTheme = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState('after');
  const clientStoreData = useSelector(state => state.clientStoreData);
  const storeInfo = clientStoreData.clientInfo?.storeInfo;
  const [activeEnable, setActiveEnable] = useState(false);
  const [loading, setLoading] = useState(false);
  const header = { authentication: storeInfo?.token };
  const [toastMsg, setToastMsg] = useState('');
  const [selector, setSelector] = useState('');

  const toggleActive = useCallback(
    () => setActiveEnable((activeEnable) => !activeEnable),
    []
  );

  const toastMarkup = activeEnable ? (
    <Toast
      duration={5000}
      content={toastMsg}
      onDismiss={toggleActive}
    />
  ) : null;

  const handleSelectChange = useCallback(
    (value) => setSelected(value),
    [],
  );
  const options = [
    { label: 'After', value: 'after' },
    { label: 'Before', value: 'before' },
  ];

  const saveSettings = async () => {
    setLoading(true);
    const data = {
      store_client_id: storeInfo?.shop_data?.store_client_id,
      theme_selector: selector,
      theme_placement: selected
    };
    const res = await ApiCall('POST', '/save_theme_settings', data, header);
    if (res?.data?.statusCode === 200) {
      setToastMsg(res.data.message);
      toggleActive();
      setLoading(false);
      navigate('/setting', {
        state: {
          config: config
        }
      });
    }
  }

  const getSettings = async () => {
    const res = await GetApiCall('GET', '/get_theme_settings', header);
    if (res?.data?.statusCode === 200) {
      const settings = res.data.data;
      setSelector(settings.theme_selector);
      setSelected(settings.theme_placement);
    }
  }

  useEffect(() => {
    if (storeInfo) {
      getSettings();
    }
  }, [storeInfo]);

  return (
    <>
      {window.location.hostname !== "localhost" ?
        <TitleBar title={"Theme setting"}>
          <button variant='breadcrumb' onClick={() => {
            navigate('/setting', {
              state: {
                config: config
              }
            })
          }}>Settings</button>
        </TitleBar> : ''}
      <Page backAction={{
        content: 'Products', onAction: () => navigate('/setting', {
          state: {
            config: config
          }
        })
      }} title={'Theme setting'}>
        <LegacyCard>
          <LegacyCard.Section title='General'>
            <p>Manage most important app settings</p>
          </LegacyCard.Section>
          <LegacyCard.Section>
            <FormLayout>
              <TextField
                type='text'
                label="Selector (space or comma separated)"
                value={selector}
                onChange={(value) => setSelector(value)}
                autoComplete="off" />
              <Select
                label="Placement"
                options={options}
                onChange={handleSelectChange}
                value={selected}
              />
            </FormLayout>
          </LegacyCard.Section>
        </LegacyCard>
        <PageActions
          primaryAction={{
            content: 'Save',
            onAction: () => saveSettings(),
            loading: loading
          }}
        />
        {toastMarkup}
        <Footer />
      </Page>
    </>
  )
}

export default SettingTheme;