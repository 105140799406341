import { LegacyCard, Page, PageActions, TextField, Toast } from '@shopify/polaris';
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ApiCall, GetApiCall } from '../helper/axios';
import { useSelector } from 'react-redux';
import { TitleBar } from "@shopify/app-bridge-react";
import Footer from './Footer';
import { config } from '../helper/commonApi';

const SettingCustomCSS = () => {
  const navigate = useNavigate();
  const clientStoreData = useSelector(state => state.clientStoreData);
  const storeInfo = clientStoreData.clientInfo?.storeInfo;
  const [activeEnable, setActiveEnable] = useState(false);
  const [loading, setLoading] = useState(false);
  const header = { authentication: storeInfo?.token };
  const [toastMsg, setToastMsg] = useState('');
  const [cssvalue, setCssvalue] = useState('');

  const toggleActive = useCallback(
    () => setActiveEnable((activeEnable) => !activeEnable),
    []
  );

  const toastMarkup = activeEnable ? (
    <Toast
      duration={5000}
      content={toastMsg}
      onDismiss={toggleActive}
    />
  ) : null;

  const saveSettings = async () => {
    setLoading(true);
    const data = {
      store_client_id: storeInfo?.shop_data?.store_client_id,
      custom_css: cssvalue
    };
    const res = await ApiCall('POST', '/save_theme_settings', data, header);
    if (res?.data?.statusCode === 200) {
      setToastMsg(res.data.message);
      toggleActive();
      setLoading(false);
      navigate('/setting', {
        state: {
          config: config
        }
      });
    }
  }

  const getSettings = async () => {
    const res = await GetApiCall('GET', '/get_css', header);
    if (res?.data?.statusCode === 200) {
      const settings = res.data.data;
      setCssvalue(settings.custom_css)
    }
  }

  useEffect(() => {
    if (storeInfo) {
      getSettings();
    }
  }, [storeInfo]);

  return (
    <>
      {window.location.hostname !== "localhost" ?
        <TitleBar title={"Custom css"}>
          <button variant='breadcrumb' onClick={() => navigate('/setting', {
            state: {
              config: config
            }
          })}>Settings</button>
        </TitleBar> : ''}
      <Page backAction={{
        content: 'Products', onAction: () => navigate('/setting', {
          state: {
            config: config
          }
        })
      }} title={'Custom css'}>
        <LegacyCard>
          <LegacyCard.Section>
            <TextField
              label="Custom css"
              multiline={10}
              autoComplete="off"
              value={cssvalue}
              onChange={(value) => setCssvalue(value)}
            />
          </LegacyCard.Section>
        </LegacyCard>
        <PageActions
          primaryAction={{
            content: 'Save',
            onAction: () => saveSettings(),
            loading: loading
          }}
        />
        <Footer />
        {toastMarkup}
      </Page>
    </>
  )
}

export default SettingCustomCSS;