import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Layout, Grid, LegacyCard, Page, Button, Toast, TextField, Text, FormLayout, DropZone, Banner, List, Form } from '@shopify/polaris';
import { ApiCall, GetApiCall } from '../helper/axios';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { TitleBar } from "@shopify/app-bridge-react";
import Footer from './Footer';
import { config } from '../helper/commonApi';

const FeatureReqComment = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const clientStoreData = useSelector(state => state.clientStoreData);
    const storeInfo = clientStoreData.clientInfo?.storeInfo;
    const shop_owner = storeInfo?.shop_data?.shop_owner;
    const header = { authentication: storeInfo?.token };
    const [activeEnable, setActiveEnable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [voterow, setVoterows] = useState([]);
    const [comrow, setComrow] = useState([]);
    const [files, setFiles] = useState();
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const hasError = rejectedFiles.length > 0;
    const [toastMsg, setToastMsg] = useState('');
    let [initialState, setInitialState] = useState({
        comment: ''
    });

    const toggleActive = useCallback(
        () => setActiveEnable((activeEnable) => !activeEnable),
        []
    );

    const toastMarkup = activeEnable ? (
        <Toast
            duration={5000}
            content={toastMsg}
            onDismiss={toggleActive}
        />
    ) : null;

    let formSchema = Yup.object().shape({
        comment: Yup.string().required('Comment field is required')
    });

    const formik = useFormik({
        initialValues: initialState,
        validationSchema: formSchema,
        enableReinitialize: true,
        onSubmit: (value, { resetForm }) => {
            saveComment();
            resetForm();
        }
    });

    const saveComment = async () => {
        setLoading(true);
        let formData = new FormData();
        formData.append('id', id);
        formData.append('comment', formik.values.comment);
        formData.append('name', shop_owner);
        if (files) {
            formData.append('file', files);
            formData.append('req_file', files.name);
        }
        let res = await ApiCall('POST', '/add_comment', formData, header);
        if (res?.data?.statusCode === 200) {
            setToastMsg(res.data.message);
            toggleActive();
            setFiles();
            setLoading(false);
            getVoter();
        }
    }

    const getVoter = async () => {
        const res = await GetApiCall('GET', `/get_voter?id=${id}`, header);
        if (res?.data?.statusCode === 200) {
            const data = res.data.data;
            let rows = [];
            let comRows = [];
            if (data.length > 0) {
                rows = data.map((item) => [
                    !item.comment && <li className='voters-li' key={item.id}>
                        <div>
                            <div className="Polaris-LegacyStack--noWrap Polaris-LegacyStack">
                                <div className="Polaris-LegacyStack__Item">
                                    <span className="Polaris-Avatar Polaris-Avatar--sizeMedium Polaris-Avatar--shapeRound">
                                        <span className="Polaris-Avatar__Initials">
                                            <span><b>{((item.voter_name).charAt(0)).toUpperCase()}</b></span>
                                        </span>
                                    </span>
                                </div>
                                <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill pt-2">
                                    <h3 className="Polaris-Text--root Polaris-Text--bodyMd">{item.voter_name}</h3>
                                </div>
                            </div>
                        </div>
                    </li>
                ]);
                comRows = data.map((item, i) => [
                    item.comment && <li className='voters-li' key={i}>
                        <div>
                            <div className="Polaris-LegacyStack--noWrap Polaris-LegacyStack">
                                <div className="Polaris-LegacyStack__Item">
                                    <span className="Polaris-Avatar Polaris-Avatar--sizeMedium Polaris-Avatar--shapeRound">
                                        <span className="Polaris-Avatar__Initials">
                                            <span><b>{((item.voter_name).charAt(0)).toUpperCase()}</b></span>
                                        </span>
                                    </span>
                                </div>
                                <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill pt-2">
                                    <h3 className="Polaris-Text--root Polaris-Text--bodyMd"><div className='cmt-txt'>{item.voter_name}</div></h3>
                                    <div className="Polaris-Text--subdued pt-2">{item.comment}</div>
                                    <div className="Polaris-Text--bodyMd">{item.create_date}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                ]);
            } else {
                rows = ([[<li className="list-li"><div className="nodata-found">No data found.</div></li>]]);
            }
            setRows(rows);
            setComrow(comRows);
        }
    }

    const getRequest = async () => {
        const res = await GetApiCall('GET', `/get_request?id=${id}`, header);
        if (res?.data?.statusCode === 200) {
            const data = res.data.data;
            let rows = [];
            if (data.length > 0) {
                rows = data.map((item) => [
                    <li key={item.id}>
                        <div>
                            <div className="Polaris-LegacyStack--noWrap Polaris-LegacyStack">
                                <div className="Polaris-LegacyStack__Item">
                                    <div id="upvoteChange" className="vote-btn cursor-pointer">
                                        <svg width="13" height="8" viewBox="0 0 13 8" fill="none">
                                            <path d="M1.44332 8.00076H11.3584C12.5602 8.00076 13.2339 6.79418 12.4914 5.97166L7.53383 0.479635C6.95774 -0.158861 5.84558 -0.158861 5.26789 0.479635L0.310347 5.97326C-0.432165 6.79418 0.241537 8.00076 1.44332 8.00076Z" fill="#5C5F62" />
                                        </svg>
                                        <div className="vote-num">
                                            <span data-upvote="20">{item.vote}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                                    <div className='req-title'>
                                        <Text variant="headingXl" as="h4">
                                            {item.title}
                                        </Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                ]);
            } else {
                rows = ([[<li className="list-li"><div className="nodata-found">No data found.</div></li>]]);
            }
            setVoterows(rows);
        }
    }
    useEffect(() => {
        getVoter();
        getRequest();
    }, [storeInfo]);

    const fileUpload = <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />;
    const uploadedFiles = files && (
        <div>{files.name}</div>
    );

    const handleDrop = useCallback((_droppedFiles, acceptedFiles, rejectedFiles) => {
        setFiles(acceptedFiles[0]);
        setRejectedFiles(rejectedFiles);
    }, []);

    const errorMessage = ((Object.values(formik.errors).length > 0 && Object.values(formik.touched).length > 0) || hasError) && (
        <Banner title={`The following errors to feature request settings:`} status="critical">
            <List type="bullet">
                {rejectedFiles.map((file, index) => (
                    <List.Item key={index}>
                        {`"${file.name}" is not supported. File type must be .gif, .jpg, .png or .pdf.`}
                    </List.Item>
                ))}
                {formik.errors.comment && <List.Item>{formik.errors.comment}</List.Item>}
            </List>
        </Banner>
    );

    return (
        <>
            {window.location.hostname !== "localhost" ?
                <TitleBar title={"Comment"}>
                    <button variant='breadcrumb' onClick={() => navigate('/feature_req', {
                        state: {
                            config: config
                        }
                    })}>Request a Feature</button>
                </TitleBar> : ''}
            <Page fullWidth backAction={{
                content: 'Back to all posts', onAction: () => navigate('/feature_req', {
                    state: {
                        config: config
                    }
                })
            }} title="Back to all posts">
                <Layout >
                    <Layout.Section>
                        {errorMessage}
                        <div className='pt-2'>
                            <Grid>
                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 3, xl: 3 }}>
                                    <div className='feature_div'>
                                        <LegacyCard title="Voters" sectioned>
                                            <ul className="Polaris-ResourceList">
                                                {rows}
                                            </ul>
                                        </LegacyCard>
                                    </div>
                                </Grid.Cell>
                                <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 9, xl: 9 }}>
                                    <LegacyCard sectioned>
                                        <div>
                                            <ul className="Polaris-ResourceList">
                                                {voterow}
                                            </ul>
                                            <ul className="Polaris-ResourceList">
                                                {comrow}
                                            </ul>
                                        </div>
                                    </LegacyCard>
                                    <LegacyCard title="Leave a comment" sectioned>
                                        <Form onSubmit={() => formik.handleSubmit()} encType='multipart/form-data' method="post">
                                            <FormLayout>
                                                <TextField
                                                    name='comment'
                                                    value={formik.values.comment}
                                                    onChange={(value) => formik.setFieldValue('comment', value)}
                                                    multiline={4}
                                                    placeholder="Type here..."
                                                    autoComplete="off"
                                                />
                                                <div className='feature_btnGrp display-flex'>
                                                    <div className='upload_icon_div'>
                                                        <div className='display-flex'>
                                                            <div className='dropzon'>
                                                                <DropZone
                                                                    accept="image/*,application/pdf"
                                                                    allowMultiple={false}
                                                                    onDrop={handleDrop}>
                                                                    {fileUpload}
                                                                </DropZone>
                                                            </div>
                                                            <div className='uploadfile-div'>
                                                                {uploadedFiles}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Button primary submit loading={loading}>Comment</Button>
                                                    </div>
                                                </div>
                                            </FormLayout>
                                        </Form>
                                    </LegacyCard>
                                </Grid.Cell>
                            </Grid>
                        </div>
                        {toastMarkup}
                    </Layout.Section>
                </Layout>
                <Footer />
            </Page>
        </>
    );
}

export default FeatureReqComment;