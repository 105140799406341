import { FooterHelp, Link } from "@shopify/polaris";
import React, { useEffect } from "react";

const Footer = () => {
  useEffect(() => {
    if (window.location.hostname !== "localhost") {
      var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
      (function () {
        const s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/670a30b24304e3196ad0a507/1i9vssh0a';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
      })();
    }
  }, []);

  return (
    <FooterHelp>
      If you need any help, please {" "}
      <Link url="mailto:info@backinstocktech.com" target="_blank">
        {" "}Contact us
      </Link>
    </FooterHelp>
  );
};
export default Footer;