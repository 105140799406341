import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ApiCall, GetApiCall } from '../helper/axios';
import { useSelector } from 'react-redux';
import { Button, ButtonGroup, Select, DataTable, Divider, FormLayout, LegacyCard, Modal, Page, Text, TextField, Toast } from '@shopify/polaris';
import { DeleteMinor, EditMajor, ViewMajor, HideMinor } from '@shopify/polaris-icons';
import { TitleBar } from "@shopify/app-bridge-react";
import Footer from './Footer';
import { config } from '../helper/commonApi';

const SettingProductAvailable = () => {
    const navigate = useNavigate();
    const clientStoreData = useSelector(state => state.clientStoreData);
    const storeInfo = clientStoreData.clientInfo?.storeInfo;
    const header = { authentication: storeInfo?.token };
    const [selected, setSelected] = useState('day');
    const [toastMsg, setToastMsg] = useState('');
    const [activeEnable, setActiveEnable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [proNumber, setProNumber] = useState(1);
    const [rows, setRows] = useState([]);
    const [proid, setProid] = useState();
    const [btn, setBtn] = useState('Add');

    const toasttoggleActive = useCallback(
        () => setActiveEnable((activeEnable) => !activeEnable),
        []
    );

    const toastMarkup = activeEnable ? (
        <Toast
            duration={5000}
            content={toastMsg}
            onDismiss={toasttoggleActive}
        />
    ) : null;

    const handleSelectChange = useCallback(
        (value) => setSelected(value),
        [],
    );

    const [active, setActive] = useState(false);
    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const openmodel = () => {
        setActive(true)
    }
    const options = [
        { label: 'Day', value: 'day' },
        { label: 'Week', value: 'week' },
        { label: 'Month', value: 'month' },
    ];

    const getSettings = async () => {
        const res = await GetApiCall('GET', '/get_proavailable_data', header);
        if (res?.data?.statusCode === 200) {
            const getProData = res.data.data;
            let rows = [];
            if (getProData.length > 0) {
                rows = getProData.map((item) => [
                    <Text>{`With in ${item.pro_available_number} ${item.pro_available_option} from today`}</Text>,
                    <ButtonGroup segmented>
                        <Button icon={parseInt(item.status) === 0 ? ViewMajor : HideMinor} onClick={() => statusUpdate(item.id, item.status)} />
                        <Button icon={EditMajor} onClick={() => setProductoption(item.id, item.pro_available_number, item.pro_available_option)} />
                        <Button icon={DeleteMinor} onClick={() => deleteData(item.id)} />
                    </ButtonGroup>
                ]);
            } else {
                rows = ([[<div className="nodata-found">No data found.</div>]]);
            }
            setRows(rows);
        }
    }

    useEffect(() => {
        if (storeInfo) {
            getSettings();
        }
    }, [storeInfo]);

    const saveProductoption = async () => {
        setLoading(true);
        setBtn('Add');
        const data = {
            store_client_id: storeInfo?.shop_data?.store_client_id,
            pro_available_number: proNumber,
            pro_available_option: selected,
        };
        let res = '';
        if (!proid) {
            res = await ApiCall('POST', '/save_proavailable_data', data, header);
        } else {
            data.id = proid;
            res = await ApiCall('PUT', '/update_proavailable_data', data, header);
        }
        if (res?.data?.statusCode === 200) {
            setToastMsg(res.data.message);
            toggleActive();
            toasttoggleActive();
            setLoading(false);
            getSettings();
            setProid('');
            setProNumber(1);
            setSelected('day');
        }
    }

    const setProductoption = async (id, proNum, proOpt) => {
        setActive(true);
        setProNumber(proNum);
        setSelected(proOpt);
        setProid(id);
        setBtn('update');
    }

    const statusUpdate = async (id, status) => {
        const prostatus = (status === "0") ? '1' : 0;
        const data = {
            status: prostatus,
            id
        };
        const res = await ApiCall('PUT', '/update_proavailable_data', data, header);
        if (res?.data?.statusCode === 200) {
            setToastMsg(res.data.message);
            toasttoggleActive();
            getSettings();
        }
    }

    const deleteData = async (id) => {
        const data = {
            id
        };
        const res = await ApiCall('DELETE', '/delete_proavailable_data', data, header);
        if (res?.data?.statusCode === 200) {
            setToastMsg(res.data.message);
            toasttoggleActive();
            getSettings();
        }
    }

    const closeaction = () => {
        setProid('');
        setProNumber(1);
        setSelected('day');
        setBtn('Add');
        toggleActive();
    }
    return (
        <>
            {window.location.hostname !== "localhost" ?
                <TitleBar title={"Product available setting"}>
                    <button variant='breadcrumb' onClick={() => navigate('/setting', {
                        state: {
                            config: config
                        }
                    })}>Email template setting</button>
                    <button variant='primary' onClick={openmodel}>Add option</button>
                </TitleBar> : ''}
            <div className='product_ava'>
                <Page backAction={{
                    content: 'Products', onAction: () => navigate('/setting', {
                        state: {
                            config: config
                        }
                    })
                }} title={'Product available setting'} primaryAction={<Button primary onClick={() => openmodel()}>Add option</Button>}>
                    <LegacyCard title='General'>
                        <LegacyCard.Section>
                            <p>Manage most important app setting</p>
                        </LegacyCard.Section>
                        <Divider />
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'numeric',

                            ]}
                            headings={[
                                'Options',
                                'Actions',

                            ]}
                            rows={rows}
                            verticalAlign="middle"
                        />

                    </LegacyCard>
                    <Footer />
                    <div style={{ height: '250px' }}>
                        <Modal
                            size="small"
                            open={active}
                            onClose={() => closeaction()}
                            title="Add option"
                            primaryAction={{
                                content: btn,
                                onAction: () => saveProductoption(),
                                loading: loading
                            }}
                            secondaryActions={[
                                {
                                    content: 'Cancel',
                                    onAction: () => closeaction(),
                                },
                            ]}
                        >
                            <Modal.Section>
                                <FormLayout>
                                    <TextField
                                        type='number'
                                        label="Day/Week/month"
                                        min={1}
                                        value={proNumber}
                                        onChange={(value) => { setProNumber(value) }}
                                        autoComplete="off" />
                                    <Select
                                        label="Option"
                                        options={options}
                                        onChange={handleSelectChange}
                                        value={selected}
                                    />
                                </FormLayout>
                            </Modal.Section>
                        </Modal>
                    </div>
                    {toastMarkup}
                </Page>
            </div>
        </>
    )
}

export default SettingProductAvailable;