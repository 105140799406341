import { Pagination, DataTable, Divider, Spinner, Icon, Box, Select, Scrollable, Button, VerticalStack, HorizontalStack, DatePicker, OptionList, Layout, LegacyCard, Page, Text, TextField, Banner, ProgressBar, HorizontalGrid, useBreakpoints, Popover, Link } from '@shopify/polaris';
import { SearchMinor, FavoriteMajor, CalendarMinor, ArrowRightMinor } from '@shopify/polaris-icons';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { PolarisVizProvider, StackedAreaChart } from "@shopify/polaris-viz";
import "@shopify/polaris-viz/build/esm/styles.css";
import { GetApiCall } from '../helper/axios';
import { TitleBar } from "@shopify/app-bridge-react";
import Footer from './Footer';

const Dashboard = () => {
    const clientStoreData = useSelector(state => state.clientStoreData);
    const storeInfo = clientStoreData.clientInfo?.storeInfo;
    const header = { authentication: storeInfo?.token };
    const store_client_id = storeInfo?.shop_data?.store_client_id;
    const total_email = storeInfo?.shop_data?.total_email;
    const plan_type = storeInfo?.shop_data?.plan_type;
    const [monthlyEmail, setMonthlyEmail] = useState(10);
    const [rows, setRows] = useState([]);
    const [currpage, setCurrPage] = useState(1);
    const [totalpage, settotalPage] = useState(1);
    const [sortdir, setSortDir] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [sortedRows, setSortedRows] = useState(null);
    const [data, setData] = useState([]);
    const [orderData, setorderData] = useState([]);
    const [popoverActive, setPopoverActive] = useState(false);
    const { mdDown, lgUp } = useBreakpoints();
    const [inputValues, setInputValues] = useState({});
    const shouldShowMultiMonth = lgUp;
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const yesterday = new Date(
        new Date(new Date().setDate(today.getDate() - 1)).setHours(0, 0, 0, 0)
    );
    const datePickerRef = useRef(null);
    const VALID_YYYY_MM_DD_DATE_REGEX = /^\d{4}-\d{1,2}-\d{1,2}/;
    const ranges = [
        {
            title: "Today",
            alias: "today",
            period: {
                since: today,
                until: today,
            },
        },
        {
            title: "Yesterday",
            alias: "yesterday",
            period: {
                since: yesterday,
                until: yesterday,
            },
        },
        {
            title: "Last 7 days",
            alias: "last7days",
            period: {
                since: new Date(
                    new Date(new Date().setDate(today.getDate() - 7)).setHours(0, 0, 0, 0)
                ),
                until: yesterday,
            },
        },
        {
            title: "Last 30 days",
            alias: "last30days",
            period: {
                since: new Date(
                    new Date(new Date().setMonth(today.getMonth() - 1)).setHours(0, 0, 0, 0)
                ),
                until: yesterday,
            },
        },
        {
            title: "Last 90 days",
            alias: "last90days",
            period: {
                since: new Date(
                    new Date(new Date().setMonth(today.getMonth() - 3)).setHours(0, 0, 0, 0)
                ),
                until: today,
            },
        },
        {
            title: "Last year",
            alias: "lastyear",
            period: {
                since: new Date(
                    new Date(new Date().setFullYear(today.getFullYear() - 1)).setHours(0, 0, 0, 0)
                ),
                until: yesterday,
            },
        },
        {
            title: "Custom",
            alias: "custom",
            period: {
                since: new Date(),
                until: today,
            },
        },
    ];
    const [activeDateRange, setActiveDateRange] = useState(ranges[4]);
    const [{ month, year }, setDate] = useState({
        month: activeDateRange.period.since.getMonth(),
        year: activeDateRange.period.since.getFullYear(),
    });

    const isDate = (date) => {
        return !isNaN(new Date(date).getDate());
    }

    const isValidYearMonthDayDateString = (date) => {
        return VALID_YYYY_MM_DD_DATE_REGEX.test(date) && isDate(date);
    }

    const isValidDate = (date) => {
        return date.length === 10 && isValidYearMonthDayDateString(date);
    }

    const parseYearMonthDayDateString = (input) => {
        const [year, month, day] = input.split("-");
        return new Date(Number(year), Number(month) - 1, Number(day));
    }

    const formatDateToYearMonthDayDateString = (date) => {
        const year = String(date.getFullYear());
        let month = String(date.getMonth() + 1);
        let day = String(date.getDate());
        if (month.length < 2) {
            month = String(month).padStart(2, "0");
        }
        if (day.length < 2) {
            day = String(day).padStart(2, "0");
        }
        return [year, month, day].join("-");
    }

    const formatDate = (date) => {
        return formatDateToYearMonthDayDateString(date);
    }

    const nodeContainsDescendant = (rootNode, descendant) => {
        if (rootNode === descendant) {
            return true;
        }
        let parent = descendant.parentNode;
        while (parent != null) {
            if (parent === rootNode) {
                return true;
            }
            parent = parent.parentNode;
        }
        return false;
    }

    const isNodeWithinPopover = (node) => {
        return datePickerRef?.current
            ? nodeContainsDescendant(datePickerRef.current, node)
            : false;
    }

    const handleStartInputValueChange = (value) => {
        setInputValues((prevState) => {
            return { ...prevState, since: value };
        });
        if (isValidDate(value)) {
            const newSince = parseYearMonthDayDateString(value);
            setActiveDateRange((prevState) => {
                const newPeriod =
                    prevState.period && newSince <= prevState.period.until
                        ? { since: newSince, until: prevState.period.until }
                        : { since: newSince, until: newSince };
                return {
                    ...prevState,
                    period: newPeriod,
                };
            });
        }
    }

    const handleEndInputValueChange = (value) => {
        setInputValues((prevState) => ({ ...prevState, until: value }));
        if (isValidDate(value)) {
            const newUntil = parseYearMonthDayDateString(value);
            setActiveDateRange((prevState) => {
                const newPeriod =
                    prevState.period && newUntil >= prevState.period.since
                        ? { since: prevState.period.since, until: newUntil }
                        : { since: newUntil, until: newUntil };
                return {
                    ...prevState,
                    period: newPeriod,
                };
            });
        }
    }

    const handleInputBlur = ({ relatedTarget }) => {
        const isRelatedTargetWithinPopover =
            relatedTarget != null && isNodeWithinPopover(relatedTarget);
        if (isRelatedTargetWithinPopover) {
            return;
        }
        setPopoverActive(false);
    }

    const handleMonthChange = (month, year) => {
        setDate({ month, year });
    }

    const handleCalendarChange = ({ start, end }) => {
        const newDateRange = ranges.find((range) => {
            return (
                range.period.since.valueOf() === start.valueOf() &&
                range.period.until.valueOf() === end.valueOf()
            );
        }) || {
            alias: "custom",
            title: "Custom",
            period: {
                since: start,
                until: end,
            },
        };
        setActiveDateRange(newDateRange);
    }

    const apply = () => {
        setPopoverActive(false);
        getEmailActivity(activeDateRange.period.since, activeDateRange.period.until);
        getorderActivity(activeDateRange.period.since, activeDateRange.period.until);
    }
    const cancel = () => {
        setPopoverActive(false);
        setActiveDateRange(ranges[0]);
    }
    useEffect(() => {
        if (activeDateRange) {
            setInputValues({
                since: formatDate(activeDateRange.period.since),
                until: formatDate(activeDateRange.period.until),
            });
            function monthDiff(referenceDate, newDate) {
                return (
                    newDate.month -
                    referenceDate.month +
                    12 * (referenceDate.year - newDate.year)
                );
            }
            const monthDifference = monthDiff(
                { year, month },
                {
                    year: activeDateRange.period.until.getFullYear(),
                    month: activeDateRange.period.until.getMonth(),
                }
            );
            if (monthDifference > 1 || monthDifference < 0) {
                setDate({
                    month: activeDateRange.period.until.getMonth(),
                    year: activeDateRange.period.until.getFullYear(),
                });
            }
        }
    }, [activeDateRange]);

    const buttonValue = activeDateRange.title === "Custom" ? activeDateRange.period.since.toDateString() + " - " + activeDateRange.period.until.toDateString() : activeDateRange.title;

    const getSubList = async (direction, page, since, until, searchVal) => {
        page = (page) ? page : 1;
        setSortDir(direction);
        let params = '';
        if (searchVal && searchVal.length) {
            params = `&search=${searchVal}`;
        }

        if (since && until) {
            since = formatDate(since);
            until = formatDate(until);
            params += `&since=${since}&until=${until}`;
        }
        const res = await GetApiCall('GET', `/get_subscribers_products?sort=${direction}&page=${page}${params}`, header);
        if (res?.data?.statusCode === 200) {
            const getSubData = res.data.data.subscripber;
            let rows = [];
            if (getSubData.length > 0) {
                rows = getSubData.map((item) => [
                    <Text>{item.product_id}</Text>,
                    <Text>{(item.variant_title === '') ? `${item.product_title}` : `${item.product_title} - ${item.variant_title}`}</Text>,
                    <Text>{item.num_of_sub}</Text>
                ]);
            } else {
                rows = ([[<div className="nodata-found">No data found.</div>]]);
            }
            setRows(rows);
            setCurrPage(page);
            settotalPage(res.data.data.totalpage);
        }
    }

    useEffect(() => {
        if (searchValue.length >= 3) {
            getSubList(sortdir, currpage, activeDateRange.period.since, activeDateRange.period.until, searchValue);
        } else {
            const sort = (sortdir === 'null') ? sortdir : 'ascending';
            getSubList(sort, currpage, activeDateRange.period.since, activeDateRange.period.until, '');
        }
    }, [searchValue, storeInfo]);

    const row = sortedRows ? sortedRows : rows;
    const handleSort = useCallback(
        (index, direction) =>
            setSortedRows(getSubList(direction)),
        [row],
    );

    const getEmailActivity = async (since, until) => {
        let param = '';
        if (since && until) {
            since = formatDate(since);
            until = formatDate(until);
            param += `&since=${since}&until=${until}`;
        }
        const res = await GetApiCall('GET', `/get_email_activity?id=${store_client_id}${param}`, header);
        if (res?.data?.statusCode === 200) {
            const data = res?.data?.data;
            const mainArr = [];
            for (var i = 0; i < data.length; i++) {
                if (data[i].email_click_data) {
                    mainArr.push({ 'name': 'Clicked', data: data[i].email_click_data });
                }
                if (data[i].email_open_data) {
                    mainArr.push({ 'name': 'Opened', data: data[i].email_open_data });
                }
                if (data[i].email_sent_data) {
                    mainArr.push({ 'name': 'Delivered', data: data[i].email_sent_data });
                }
            }
            setData(mainArr);
        }
    }

    const getorderActivity = async (since, until) => {
        let param = '';
        if (since && until) {
            since = formatDate(since);
            until = formatDate(until);
            param += `&since=${since}&until=${until}`;
        }
        const res = await GetApiCall('GET', `/get_order_activity?id=${store_client_id}${param}`, header);
        if (res?.data?.statusCode === 200) {
            const data = res?.data?.data;
            const orderArr = [];
            for (var i = 0; i < data.length; i++) {
                orderArr.push({ 'name': 'Total Amount', data: data[i].order_data });
            }
            setorderData(orderArr);
        }
    }

    useEffect(() => {
        getEmailActivity(activeDateRange.period.since, activeDateRange.period.until);
        const weekstart = new Date(
            new Date(new Date().setDate(today.getDate() - 7)).setHours(0, 0, 0, 0)
        );
        getorderActivity(weekstart, today);
    }, []);

    useEffect(() => {
        if (plan_type === '1') {
            setMonthlyEmail(300);
        } else if (plan_type === '2') {
            setMonthlyEmail(500);
        } else if (plan_type === '3') {
            setMonthlyEmail(1200);
        }
    }, []);

    return (
        <>
            {window.location.hostname !== "localhost" ? <TitleBar title={"Dashboard"} /> : ''}
            <Page>
                <Layout >
                    <Layout.Section>
                        <Banner
                            title="How has your experience been?"
                            status="info"
                            onDismiss={() => { }}
                        >
                            <div className='dash_banner'>
                                <p> Rate us by clicking on the stars</p> <span className='star_icon'><Icon
                                    source={FavoriteMajor}
                                    color="base"
                                /></span><span><Icon
                                    source={FavoriteMajor}
                                    color="base"
                                /></span><span><Icon
                                    source={FavoriteMajor}
                                    color="base"
                                /></span><span><Icon
                                    source={FavoriteMajor}
                                    color="base"
                                /></span><span><Icon
                                    source={FavoriteMajor}
                                    color="base"
                                /></span></div>
                        </Banner>
                    </Layout.Section>
                    {total_email === monthlyEmail ? <>
                        <Layout.Section>
                            <Banner
                                status="warning"
                            >
                                <p>Please upgrade your plan. <Link url={`/pricing_plan`} external>Click here</Link></p>
                            </Banner>
                        </Layout.Section>
                    </> : ''}
                    <Layout.Section>
                        <div className='dash_rangline'>
                            <LegacyCard sectioned title="Limit of email usage">
                                <span>{`You have used ${total_email} of limit ${monthlyEmail} this month.`}</span>
                                <div className='rang_limit'>
                                    <ProgressBar progress={(total_email / monthlyEmail) * 100} size="small" animated={Boolean} />
                                    <p>{Math.round((total_email / monthlyEmail) * 100)}%</p>
                                </div>
                            </LegacyCard>
                        </div>
                    </Layout.Section>
                    <Layout.Section>
                        <Divider />
                    </Layout.Section>
                    <Layout.Section>
                        <div className='pb-3'>
                            <div className='Polaris-Page-Header__Row'>
                                <div className="Polaris-Page-Header__TitleWrapper">
                                    <div className="Polaris-Header-Title__TitleAndSubtitleWrapper">
                                        <div className="Polaris-Header-Title">Popular subscribers products</div>
                                    </div>
                                </div>
                                <div className="Polaris-Page-Header__RightAlign">
                                    <div className="Polaris-Page-Header__Actions">
                                        <Popover
                                            active={popoverActive}
                                            autofocusTarget="none"
                                            preferredAlignment="left"
                                            preferredPosition="below"
                                            fluidContent
                                            sectioned={false}
                                            fullHeight
                                            activator={
                                                <Button
                                                    size="slim"
                                                    icon={CalendarMinor}
                                                    onClick={() => setPopoverActive(!popoverActive)}
                                                >
                                                    {buttonValue}
                                                </Button>
                                            }
                                            onClose={() => setPopoverActive(false)}
                                        >
                                            <Popover.Pane fixed>
                                                <HorizontalGrid
                                                    columns={{
                                                        xs: "1fr",
                                                        mdDown: "1fr",
                                                        md: "max-content max-content",
                                                    }}
                                                    gap={0}
                                                    ref={datePickerRef}
                                                >
                                                    <Box
                                                        maxWidth={mdDown ? "516px" : "212px"}
                                                        width={mdDown ? "100%" : "212px"}
                                                        padding={{ xs: 500, md: 0 }}
                                                        paddingBlockEnd={{ xs: 100, md: 0 }}
                                                    >
                                                        {mdDown ? (
                                                            <Select
                                                                label="dateRangeLabel"
                                                                labelHidden
                                                                onChange={(value) => {
                                                                    const result = ranges.find(
                                                                        ({ title, alias }) => title === value || alias === value
                                                                    );
                                                                    setActiveDateRange(result);
                                                                }}
                                                                value={activeDateRange?.title || activeDateRange?.alias || ""}
                                                                options={ranges.map(({ alias, title }) => title || alias)}
                                                            />
                                                        ) : (
                                                            <Scrollable style={{ height: "334px" }}>
                                                                <OptionList
                                                                    options={ranges.map((range) => ({
                                                                        value: range.alias,
                                                                        label: range.title,
                                                                    }))}
                                                                    selected={activeDateRange.alias}
                                                                    onChange={(value) => {
                                                                        setActiveDateRange(
                                                                            ranges.find((range) => range.alias === value[0])
                                                                        );
                                                                    }}
                                                                />
                                                            </Scrollable>
                                                        )}
                                                    </Box>
                                                    <Box padding={{ xs: 500 }} maxWidth={mdDown ? "320px" : "516px"}>
                                                        <VerticalStack gap="400">
                                                            <HorizontalStack gap="200">
                                                                <div style={{ flexGrow: 1 }}>
                                                                    <TextField
                                                                        role="combobox"
                                                                        label={"Since"}
                                                                        labelHidden
                                                                        prefix={<Icon source={CalendarMinor} />}
                                                                        value={inputValues.since}
                                                                        onChange={handleStartInputValueChange}
                                                                        onBlur={handleInputBlur}
                                                                        autoComplete="off"
                                                                    />
                                                                </div>
                                                                <Icon source={ArrowRightMinor} />
                                                                <div style={{ flexGrow: 1 }}>
                                                                    <TextField
                                                                        role="combobox"
                                                                        label={"Until"}
                                                                        labelHidden
                                                                        prefix={<Icon source={CalendarMinor} />}
                                                                        value={inputValues.until}
                                                                        onChange={handleEndInputValueChange}
                                                                        onBlur={handleInputBlur}
                                                                        autoComplete="off"
                                                                    />
                                                                </div>
                                                            </HorizontalStack>
                                                            <div>
                                                                <DatePicker
                                                                    month={month}
                                                                    year={year}
                                                                    selected={{
                                                                        start: activeDateRange.period.since,
                                                                        end: activeDateRange.period.until,
                                                                    }}
                                                                    onMonthChange={handleMonthChange}
                                                                    onChange={handleCalendarChange}
                                                                    multiMonth={shouldShowMultiMonth}
                                                                    allowRange
                                                                />
                                                            </div>
                                                        </VerticalStack>
                                                    </Box>
                                                </HorizontalGrid>
                                            </Popover.Pane>
                                            <Popover.Pane fixed>
                                                <Popover.Section>
                                                    <HorizontalStack align="end">
                                                        <Button onClick={cancel}>Cancel</Button>
                                                        <div className='apply_btn'>
                                                            <Button primary onClick={apply}>
                                                                Apply
                                                            </Button>
                                                        </div>
                                                    </HorizontalStack>
                                                </Popover.Section>
                                            </Popover.Pane>
                                        </Popover>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='dash_datatable_maincls'>
                            <LegacyCard>
                                <div className='text'>
                                    <TextField
                                        placeholder="Search"
                                        prefix={<Icon source={SearchMinor} color="base" />}
                                        value={searchValue}
                                        onChange={(value) => setSearchValue(value)}
                                    // connectedRight={
                                    //     <div className='filter_icon'> <Icon
                                    //         source={SortMinor}
                                    //         color="base"
                                    //     />
                                    //     </div>
                                    // }
                                    />
                                </div>
                                <Divider />
                                <div className='dashboard_datatable'>
                                    <DataTable
                                        columnContentTypes={[
                                            'text',
                                            'text',
                                            'text',

                                        ]}
                                        headings={[
                                            'Product id',
                                            'Product name',
                                            'Number of Subscriber',
                                        ]}
                                        rows={rows.length === 0 ?
                                            [[<div className='spinnerDiv'>
                                                <Spinner
                                                    accessibilityLabel="Spinner example"
                                                    size="large"
                                                />
                                            </div>]] :
                                            rows}
                                        sortable={[true, false, false]}
                                        onSort={handleSort}
                                    />
                                    {totalpage > 1 ? (
                                        <div className='pagination'>
                                            <Pagination
                                                hasPrevious={currpage === 1 || currpage > totalpage ? false : true}
                                                onPrevious={() => {
                                                    getSubList(sortdir, currpage - 1, searchValue);
                                                }}
                                                previousTooltip="Previous"
                                                hasNext={totalpage === currpage ? false : true}
                                                nextTooltip="Next"
                                                onNext={() => {
                                                    getSubList(sortdir, currpage + 1, searchValue);
                                                }}
                                            />
                                        </div>
                                    ) : ''}
                                </div>
                            </LegacyCard>
                        </div>
                    </Layout.Section>
                    <Layout.Section>
                        <div className='email_activity'>
                            <LegacyCard title="Email activity" sectioned>
                                <PolarisVizProvider>
                                    <StackedAreaChart
                                        data={data}
                                        isAnimated={false}
                                    />
                                </PolarisVizProvider>
                            </LegacyCard>
                        </div>
                    </Layout.Section>
                    <Layout.Section>
                        <LegacyCard title="Order amount pre week" sectioned>
                            <PolarisVizProvider>
                                <StackedAreaChart
                                    data={orderData}
                                    isAnimated={false}
                                />
                            </PolarisVizProvider>
                        </LegacyCard>
                    </Layout.Section>
                </Layout>
                <Footer />
            </Page>
        </>
    )
}

export default Dashboard;