import { Button, ButtonGroup, Layout, LegacyCard, Page, Toast, Modal, Grid } from '@shopify/polaris'
import { DeleteMinor, DuplicateMinor, EditMinor, HideMinor, ViewMinor } from '@shopify/polaris-icons';
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiCall, GetApiCall } from '../helper/axios';
import { useSelector } from 'react-redux';
import { TitleBar } from "@shopify/app-bridge-react";
import Footer from './Footer';
import { config } from '../helper/commonApi';

const SettingEmail = () => {
    const navigate = useNavigate();
    const clientStoreData = useSelector(state => state.clientStoreData);
    const storeInfo = clientStoreData.clientInfo?.storeInfo;
    const [activeEnable, setActiveEnable] = useState(false);
    const header = { authentication: storeInfo?.token };
    const [toastMsg, setToastMsg] = useState('');
    const [listItem, setListItem] = useState([]);
    const [active, setActive] = useState(false);
    const [delid, setDelid] = useState('');
    const [count, setcount] = useState('');

    const editbutton = () => {
        navigate('/edit-setting-email')
    }

    const toggleActive = useCallback(
        () => setActiveEnable((activeEnable) => !activeEnable),
        []
    );

    const toastMarkup = activeEnable ? (
        <Toast
            duration={5000}
            content={toastMsg}
            onDismiss={toggleActive}
        />
    ) : null;

    const getTemplate = async () => {
        let response = await GetApiCall("GET", `/get-emailtemplate`, header);
        if (response?.data?.statusCode === 200) {
            let resData = response.data?.data;
            setListItem(resData);
            setcount(resData.length)
            setTimeout(() => {
                resData.map((item, i) =>
                    document.getElementById('previewEmailDiv_' + i).innerHTML = JSON.parse(atob(item?.email_html))
                )
            }, 100);
        }
    }

    useEffect(() => {
        if (storeInfo) {
            getTemplate();
        }
    }, [storeInfo]);

    const deleteTemp = async (id) => {
        if (id) {
            let response = await ApiCall("DELETE", `/delete-emailtemplate`, { id: id }, header);
            if (response?.data?.statusCode === 200) {
                setActive(false);
                setToastMsg(response?.data?.message);
                getTemplate();
            }
        }
    }

    const handleChange = (id) => {
        setActive((active) => !active);
        setDelid(id);
    };

    const duplicateTemp = async (subject, from_address, html, design) => {
        const data = {
            store_client_id: storeInfo?.shop_data?.store_client_id,
            email_sub: subject,
            from_email_address: from_address,
            email_design: design,
            email_html: html
        };
        const res = await ApiCall('POST', '/save_email_temp', data, header);
        if (res?.data?.statusCode === 200) {
            setToastMsg(res.data.message);
            toggleActive();
            getTemplate();
        }
    }

    const changeStatus = async (id, status) => {
        const data = {
            status: (status === '0') ? 1 : 0
        }
        const res = await ApiCall('PUT', `/update_email_temp?id=${id}`, data, header);
        if (res?.data?.statusCode === 200) {
            setToastMsg(res.data.message);
            toggleActive();
            getTemplate();
        }
    }

    return (
        <>
            <TitleBar title={"Email template setting"}>
                <button variant='breadcrumb' onClick={() => navigate('/setting', {
                    state: {
                        config: config
                    }
                })}>Settings</button>
                <button variant='primary' onClick={() => editbutton()}>Create templates</button>
            </TitleBar>
            <Page fullWidth backAction={{
                content: 'Email template setting', onAction: () => navigate('/setting', {
                    state: {
                        config: config
                    }
                })
            }} title={'Email template setting'}>
                <Layout>
                    <Layout.Section>
                        <Grid columns={{ sm: 4 }}>
                            {listItem.length > 0 && listItem.map((item, j) =>
                                [<Grid.Cell columnSpan={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }} key={j}>
                                    <div className='main-preview-div'>
                                        <LegacyCard>
                                            <LegacyCard.Section>
                                                <div id={`previewEmailDiv_${j}`} className='preview-email-div'>
                                                </div>
                                                <div className="btn-grp">
                                                    <ButtonGroup segmented>
                                                        {count !== 1 && <Button icon={(item?.status === '1') ? HideMinor : ViewMinor} onClick={() => changeStatus(item?.id, item?.status)} />}
                                                        <Button icon={EditMinor} onClick={() => navigate(`/edit-setting-email/${item?.id}`)} />
                                                        <Button icon={DuplicateMinor} onClick={() => duplicateTemp(item?.email_sub, item?.from_email_address, item?.email_html, item?.email_design)} />
                                                        {count !== 1 && <Button icon={DeleteMinor} onClick={() => handleChange(item?.id)} />}
                                                    </ButtonGroup>
                                                </div>
                                            </LegacyCard.Section>
                                        </LegacyCard>
                                    </div>
                                </Grid.Cell>]
                            )}
                        </Grid>
                    </Layout.Section>
                </Layout>
                <Modal
                    open={active}
                    onClose={handleChange}
                    title="Delete Template"
                    primaryAction={{
                        destructive: true,
                        content: 'Delete',
                        onAction: () => deleteTemp(delid),
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: handleChange,
                        },
                    ]}
                >
                    <Modal.Section>
                        Are you Sure? You can delete this template?
                    </Modal.Section>
                </Modal>
                {toastMarkup}
                <Footer />
            </Page>
        </>
    )
}

export default SettingEmail;